import React from "react"
import { navigate } from "gatsby-link"
import chroma from "chroma-js"
import styled from "styled-components/macro"
import Recaptcha from "react-google-recaptcha"
import Layout from "../components/layout"

const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY
if (typeof RECAPTCHA_KEY === "undefined") {
  throw new Error(`
  Env var RECAPTCHA_KEY is undefined! 
  You probably forget to set it in your Netlify build environment variables. 
  Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
  Note this demo is specifically for Recaptcha v2
  `)
}

const ContactWrapper = styled.div`
  margin: 0 auto;
  max-width: 100%;
  label {
    font-size: ${props => props.theme.fontSizes[1]};
  }
  h3 {
    margin-top: 3rem;
  }
  @media ${props => props.theme.screenSizes.tablet} {
    max-width: 40ch;
  }
`
const Subhead = styled.h2`
  text-align: center;
  font-size: ${props => props.theme.fontSizes[2]};
  margin-top: 0;
  margin-bottom: 1rem;
  @media ${props => props.theme.screenSizes.tablet} {
    font-size: ${props => props.theme.fontSizes[4]};
    margin-bottom: 2rem;
  }
`
const StyledInput = styled.input`
  font-size: ${props => props.theme.fontSizes[2]};
  border: solid 2px ${props => props.theme.colors.text};
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.background};
  outline: none;
  border-radius: 6px;
  margin-top: 5px;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  &:focus {
    transition: box-shadow 0.1s ease;
    box-shadow: 0 0 0 4px ${props => chroma(props.theme.colors.text).alpha(0.5)};
  }
  @media ${props => props.theme.screenSizes.tablet} {
    width: 40ch;
  }
`

const StyledTextArea = styled.textarea`
  font-size: ${props => props.theme.fontSizes[2]};
  border: solid 2px ${props => props.theme.colors.text};
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.background};
  outline: none;
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
  height: 10rem;
  padding: 1rem;
  &:focus {
    transition: box-shadow 0.1s ease;
    box-shadow: 0 0 0 4px ${props => chroma(props.theme.colors.text).alpha(0.5)};
  }
  @media ${props => props.theme.screenSizes.tablet} {
    width: 40ch;
  }
`

const SubmitButton = styled.button`
  background: ${props => props.theme.colors.text};
  color: ${props => props.theme.colors.background};
  text-shadow: none;
  font-weight: ${props => props.theme.fontWeights.black};
  font-size: ${props => props.theme.fontSizes[2]};
  line-height: 1;
  border: none;
  border-radius: 3px;
  padding: 0.75rem 1rem;
  margin-top: 3rem;
  cursor: pointer;
  text-decoration: none;
  display: block;
  width: 100%;
  &:hover {
    transition: box-shadow 0.1s ease;
    box-shadow: 0 0 0 4px ${props => chroma(props.theme.colors.text).alpha(0.5)};
  }
  @media ${props => props.theme.screenSizes.tablet} {
    display: inline-block;
    width: auto;
  }
`

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

export default function Contact() {
  const [state, setState] = React.useState({})
  const recaptchaRef = React.createRef()

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleAttachment = e => {
    setState({ ...state, [e.target.name]: e.target.files[0] })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()
    if (recaptchaValue) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          "g-recaptcha-response": recaptchaValue,
          ...state,
        }),
      })
        .then(() => navigate(form.getAttribute("action")))
        .catch(error => alert(error))
    }
  }

  return (
    <Layout>
      <ContactWrapper>
        <Subhead>Suggest a destination</Subhead>
        <form
          name="file-upload"
          method="post"
          action="/thanks"
          data-netlify="true"
          data-netlify-recaptcha="true"
          onSubmit={handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="file-upload" />
          <h3>Your contact info</h3>
          <p>
            <label htmlFor="name">
              Name (required)
              <br />
              <StyledInput
                id="name"
                type="text"
                name="name"
                onChange={handleChange}
                required
              />
            </label>
          </p>
          <p>
            <label htmlFor="email">
              Email address (required)
              <br />
              <StyledInput
                id="email"
                type="email"
                name="email"
                onChange={handleChange}
                required
              />
            </label>
          </p>
          <p>
            <label htmlFor="website">
              Website
              <br />
              <StyledInput
                id="website"
                type="url"
                name="website"
                onChange={handleChange}
              />
            </label>
          </p>
          <h3>Destination</h3>
          <p>
            <label htmlFor="destination">
              What should be added?
              <br />
              <StyledInput
                id="destination"
                type="text"
                name="destination"
                onChange={handleChange}
              />
            </label>
          </p>
          <p>
            <label htmlFor="destinationLink">
              Link to an image
              <br />
              <StyledInput
                id="destinationLink"
                type="text"
                name="destinationLink"
                onChange={handleChange}
              />
            </label>
          </p>
          <p>
            <label>
              Upload an image (recommended size: 1920x1080)
              <br />
              <input
                type="file"
                name="attachment"
                onChange={handleAttachment}
              />
            </label>
          </p>
          <h3>Additional comments</h3>
          <p>
            <StyledTextArea />
          </p>
          <Recaptcha ref={recaptchaRef} theme="dark" sitekey={RECAPTCHA_KEY} />
          <p>
            <SubmitButton type="submit">Submit</SubmitButton>
          </p>
        </form>
      </ContactWrapper>
    </Layout>
  )
}
